export const environment = {
      baseURL: 'https://testapi.antimgps.com/api/v1',
      imageURL: 'https://testapi.antimgps.com',
      tracCarUrl: 'https://track.antimgps.com/api/',
      sessionCookie: 'JSESSIONID=node0157ojy7cjzkmrwsplbk1dn0s71.node0',
      token: 'SDBGAiEAqrmrIQAoYGE93v3e8JlV4vCSbi5TRgwsT3zcJSbG8ccCIQDHonAf7Q0Wvlm9zU547Va50ersGPkOjgEjVzQncoNOE3sidSI6MSwiZSI6IjMwMDAtMDEtMTNUMTg6MzA6MDAuMDAwKzAwOjAwIn0',
      webSocketUrl: 'wss://track.antimgps.com/api/socket?token=',
      firebase: {
            apiKey: "AIzaSyB3GFlQmYk9PhNplYth_PcyLr6ugAuPo5I",
            authDomain: "gps-tracker-a09b3.firebaseapp.com",
            projectId: "gps-tracker-a09b3",
            storageBucket: "gps-tracker-a09b3.appspot.com",
            messagingSenderId: "216122169893",
            appId: "1:216122169893:web:53f565a11d11c0bce3bdcc",
            measurementId: "G-Y82C6PP7TF"
      }
};
